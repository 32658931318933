*{
  margin:  0;
  padding: 0;
  box-sizing: border-box;
}  

.cart-button {
  width: 50px;
}

